import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, Stack, Typography } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { Link } from 'react-router-dom';

import { StopAttribute } from 'dto/stop';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'block',
      padding: theme.spacing(3),
      color: 'inherit',
      textDecoration: 'none',
      background: theme.palette.background.default,
    },
  }),
  {
    name: 'AttributeCard',
  }
);

interface AttributeCardProps {
  attribute: StopAttribute;
}

export const AttributeCard: FC<AttributeCardProps> = ({ attribute }) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      variant="outlined"
      component={Link}
      to={`/attributes/edit/${attribute.id}`}
    >
      <Typography variant="h2" color="inherit">
        <strong>{attribute.name}</strong>
      </Typography>

      <Stack direction="row" justifyContent="flex-start" gap={0.5}>
        <span>
          <TransField i18nKey="translations" />
        </span>
        <span>({attribute.localizations.length})</span>
      </Stack>
    </Card>
  );
};
