import { createAsyncThunk } from 'store/utils';
import { api } from '@fleet/shared';
import { createAction } from '@reduxjs/toolkit';
import { currentAttributeSelector } from 'features/attribute/attributeSelectors';
import { Classifier } from '@fleet/shared/dto/classifier';
import {
  addOrUpdateElementInArray,
  removeElementsFromArray,
} from 'helpers/array';
import { StopAttribute, StopAttributeLocalization } from 'dto/stop';

export const setAttribute = createAction<StopAttribute | undefined>(
  'stopAttribute/setAttribute'
);

export const getAttributes = createAsyncThunk<Array<StopAttribute>>(
  'stopAttribute/getAttributes',
  async () =>
    (await api.get<{ items: Array<StopAttribute> }>(`/stop-attributes`)).data
      .items
);

export const getAttribute = createAsyncThunk<
  StopAttribute,
  StopAttribute['id']
>(
  'stopAttribute/getAttribute',
  async (id) => (await api.get<StopAttribute>(`/stop-attributes/${id}`)).data
);

export const createOrUpdateAttribute = createAsyncThunk<
  StopAttribute,
  Omit<StopAttribute, 'localizations'>
>(
  'stopAttribute/createOrUpdateAttribute',
  async ({ id, ...rest }, { dispatch }) => {
    const res = (
      await (id ? api.put : api.post)<StopAttribute>(
        `/stop-attributes${id ? `/${id}` : ''}`,
        rest
      )
    ).data;

    dispatch(setAttribute(res));

    return res;
  }
);

export const deleteAttribute = createAsyncThunk<void, StopAttribute['id']>(
  'stopAttribute/deleteAttribute',
  async (id, { dispatch }) => {
    await api.delete(`/stop-attributes/${id}`);

    dispatch(setAttribute());
  }
);

export const updateAttributeLocalizationList = createAction<
  Array<StopAttributeLocalization>
>('stopAttribute/updateStopAttributeLocalizationList');

export const createOrUpdateAttributeLocalization = createAsyncThunk<
  void,
  Partial<StopAttributeLocalization>
>(
  'stopAttribute/createOrUpdateAttributeLocalization',
  async ({ id: localizationId, ...rest }, { getState, dispatch }) => {
    const currentStopAttribute = currentAttributeSelector(getState());

    if (!currentStopAttribute) {
      return;
    }

    const res = (
      await (localizationId ? api.put : api.post)<{
        id: number;
        description: string;
        culture: Classifier;
      }>(
        `/stop-attributes/${currentStopAttribute.id}/localizations${
          localizationId ? `/${localizationId}` : ''
        }`,
        rest
      )
    ).data;

    const newLocalizationArray = addOrUpdateElementInArray(
      {
        id: res.id,
        description: res.description,
        cultureId: res.culture.id,
      },
      currentStopAttribute!.localizations
    );

    dispatch(updateAttributeLocalizationList(newLocalizationArray));
  }
);

export const deleteAttributeLocalization = createAsyncThunk<
  void,
  Array<StopAttributeLocalization['id']>
>(
  'stopAttribute/deleteStopAttributeLocalization',
  async (ids, { getState, dispatch }) => {
    const currentStopAttribute = currentAttributeSelector(getState());

    await Promise.all(
      ids.map((localizationId) =>
        api.delete<StopAttributeLocalization>(
          `/stop-attributes/${
            currentAttributeSelector(getState())?.id
          }/localizations/${localizationId}`
        )
      )
    );

    const newLocalizationArray = removeElementsFromArray(
      ids,
      currentStopAttribute!.localizations
    );

    dispatch(updateAttributeLocalizationList(newLocalizationArray));
  }
);
