import { FC, useMemo } from 'react';
import { Accordion, AccordionPanel } from '@fleet/shared';
import { Typography } from '@mui/material';
import { TransTitle } from 'i18n/trans/title';
import { useSelector } from 'store/utils';
import { currentAttributeSelector } from 'features/attribute/attributeSelectors';
import { AttributeLocalizations } from 'routes/Attributes/Accordion/AttributeLocalizations';

export const AttributeAccordion: FC = () => {
  const currentStopAttribute = useSelector(currentAttributeSelector);
  const panels = ['localizations'] as const;

  const componentMap = useMemo(
    () => ({
      localizations: <AttributeLocalizations />,
    }),
    []
  );

  return (
    <Accordion controlled={false}>
      {panels.map((key) => (
        <AccordionPanel
          key={key}
          id={key}
          summary={
            <Typography variant="subtitle">
              <TransTitle i18nKey={key} />
              &nbsp;
              <Typography component="span" variant="body2">
                ({currentStopAttribute?.[key].length})
              </Typography>
            </Typography>
          }
        >
          {componentMap[key]}
        </AccordionPanel>
      ))}
    </Accordion>
  );
};
