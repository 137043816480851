import { createReducer } from '@reduxjs/toolkit';
import {
  getAttribute,
  getAttributes,
  setAttribute,
  updateAttributeLocalizationList,
} from 'features/attribute/attributeActions';
import { StopAttribute } from 'dto/stop';

export interface StopAttributeState {
  current?: StopAttribute;
  list: Array<StopAttribute>;
}

const initialState: StopAttributeState = {
  list: [],
};

export const attributeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setAttribute, (state, action) => {
      state.current = action.payload;
    })
    .addCase(getAttributes.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(getAttribute.fulfilled, (state, action) => {
      state.current = action.payload;
    })
    .addCase(updateAttributeLocalizationList, (state, action) => {
      state.current!.localizations = action.payload;
    });
});
