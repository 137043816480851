import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransButton = createTrans({
  add: <Trans i18nKey="button.add" defaults="Add" />,
  addNew: <Trans i18nKey="button.addNew" defaults="Add new" />,
  cancel: <Trans i18nKey="button.cancel" defaults="Cancel" />,
  close: <Trans i18nKey="button.close" defaults="Close" />,
  create: <Trans i18nKey="button.create" defaults="Create" />,
  delete: <Trans i18nKey="button.delete" defaults="Delete" />,
  deleteSelected: (
    <Trans i18nKey="button.deleteSelected" defaults="Delete selected" />
  ),
  edit: (
    <Trans
      i18nKey="button.edit"
      defaults="(0-1)[Edit];(2-inf)[Edit ({{count}})];"
    />
  ),
  resetChanges: (
    <Trans i18nKey="button.resetChanges" defaults="Reset changes" />
  ),
  resetFields: <Trans i18nKey="button.resetFields" defaults="Reset fields" />,
  resetFilters: (
    <Trans i18nKey="button.resetFilters" defaults="Reset filters" />
  ),
  save: <Trans i18nKey="button.save" defaults="Save" />,
  search: <Trans i18nKey="button.search" defaults="Search" />,
  searchable: (
    <Trans
      i18nKey="button.searchable"
      defaults="Searchable stops appear for the end users in sales search results and can be bought tickets to"
    />
  ),
});
