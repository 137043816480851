import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTableHead = createTrans({
  cityParish: <Trans i18nKey="table.head.cityParish" defaults="City/Parish" />,
  code: <Trans i18nKey="table.head.code" defaults="Code" />,
  country: <Trans i18nKey="table.head.country" defaults="Country" />,
  county: <Trans i18nKey="table.head.county" defaults="County" />,
  description: (
    <Trans i18nKey="table.head.description" defaults="Description" />
  ),
  language: <Trans i18nKey="table.head.language" defaults="Language" />,
  localizations: (
    <Trans i18nKey="table.head.localizations" defaults="Localizations" />
  ),
  name: <Trans i18nKey="table.head.name" defaults="Name" />,
  stopCodeListId: (
    <Trans i18nKey="table.head.stopCodeListId" defaults="Code list" />
  ),
  stopName: <Trans i18nKey="table.head.stopName" defaults="Stop name" />,
  street: <Trans i18nKey="table.head.street" defaults="Street" />,
  streetAddress: (
    <Trans i18nKey="table.head.streetAddress" defaults="Street address" />
  ),
  streetHouseNumber: (
    <Trans
      i18nKey="table.head.streetHouseNumber"
      defaults="Street, house number"
    />
  ),
  wheelchair: (
    <Trans i18nKey="field.wheelchair" defaults="Wheelchair accessible" />
  ),
});
