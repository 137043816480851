import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTitle = createTrans({
  localizations: (
    <Trans i18nKey="title.localizations" defaults="Localizations" />
  ),
  stopAttribute: (
    <Trans i18nKey="title.stopAttribute" defaults="Stop attribute" />
  ),
  stopAttributes: (
    <Trans i18nKey="title.stopAttributes" defaults="Stop attributes" />
  ),
});
