export const addOrUpdateElementInArray = <T extends { id: T['id'] }>(
  element: T,
  list: Array<T>
) => {
  const newList = [...list];
  const index = list.findIndex((item) => item.id === element.id);

  if (index !== -1) {
    newList[index!] = element;
  } else {
    newList.push(element);
  }

  return newList;
};

export const removeElementsFromArray = <T extends { id: T['id'] }>(
  elementIds: Array<T['id']>,
  list: Array<T>
) => list.filter((item) => !elementIds.includes(item.id));
