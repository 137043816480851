import { createAsyncThunk } from 'store/utils';
import {
  Stop,
  StopAttribute,
  StopCode,
  StopLocalization,
  StopPayload,
  StopPlatform,
  StopPlatformLocalization,
  StopSearchFilter,
  stopSearchFilterRequiredFields,
} from 'dto/stop';
import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';
import { Pagination } from '@fleet/shared/dto/pagination';
import { api } from '@fleet/shared';
import { currentStopSelector } from 'features/stop/stopSelectors';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _pick from 'lodash/pick';

export const setStopsFilter =
  createAction<Partial<StopSearchFilter>>('stops/setFilter');

export const clearStopsList = createAction('stops/clearList');

export const getStopsList = createAsyncThunk<
  Pagination<Stop> | undefined,
  Partial<StopSearchFilter> | undefined
>('stop/getList', async (filter, { dispatch, getState }) => {
  const filterParams = filter || getState().stops.filter;
  filter && dispatch(setStopsFilter(filter));
  if (
    _isEmpty(
      _filter(_pick(filterParams, stopSearchFilterRequiredFields), Boolean)
    )
  ) {
    return;
  }

  return (
    await api.get<Pagination<Stop>>(
      `/stops${qs.stringify(filterParams, {
        addQueryPrefix: true,
        skipNulls: true,
        strictNullHandling: true,
      })}`
    )
  ).data;
});

export const deleteStops = createAsyncThunk<void, Array<Stop['id']>>(
  'stops/delete',
  async (stopsIds) => {
    await Promise.all(stopsIds.map((id) => api.delete(`/stops/${id}`)));
  }
);

export const getStop = createAsyncThunk<Stop, Stop['id']>(
  'stop/get',
  async (id, { dispatch }) => {
    const { data } = await api.get(`/stops/${id}`);
    const [localizations, codes, platforms, attributes] = await Promise.all([
      dispatch(getStopLocalizations(data.guid)).unwrap(),
      dispatch(getStopCodes(data.guid)).unwrap(),
      dispatch(getStopPlatforms(data.guid)).unwrap(),
      dispatch(getStopAttributes(data.guid)).unwrap(),
    ]);

    return {
      ...data,
      ...localizations,
      ...codes,
      ...platforms,
      ...attributes,
    };
  }
);

export const createStop = createAsyncThunk<Stop, StopPayload>(
  'stop/create',
  async (stopPayload, { dispatch }) => {
    const { data } = await api.post('/stops', stopPayload);
    return await dispatch(getStop(data.id)).unwrap();
  }
);

export const updateStop = createAsyncThunk<Stop, StopPayload>(
  'stop/update',
  async ({ id, ...stopPayload }, { dispatch }) => {
    await api.put(`/stops/${id}`, stopPayload);
    return await dispatch(getStop(id)).unwrap();
  }
);

export const deleteStop = createAsyncThunk<never, Stop['id']>(
  'stop/delete',
  async (id) => {
    return await api.delete(`/stops/${id}`);
  }
);

export const clearStop = createAction('stop/clear');

export const getStopLocalizations = createAsyncThunk<
  { localizations: Array<StopLocalization> },
  Stop['guid']
>('stop/getLocalizations', async (stopId) => ({
  localizations: (await api.get(`/stops/${stopId}/localizations`)).data.items,
}));

export const createUpdateStopLocalization = createAsyncThunk<
  StopLocalization,
  StopLocalization
>('stop/createUpdateLocalization', (payload, { getState }) =>
  (async (stopId) =>
    (
      await (payload.id ? api.put : api.post)(
        payload.id
          ? `/stops/${stopId}/localizations/${payload.id}`
          : `/stops/${stopId}/localizations`,
        {
          ...payload,
          languageId: payload.culture.id,
        }
      )
    ).data)(currentStopSelector(getState())!.guid)
);

export const deleteStopLocalizations = createAsyncThunk<void, Array<string>>(
  'stop/deleteLocalizations',
  async (stopLocalizationIds, { getState }) => {
    const stopId = currentStopSelector(getState())!.guid;
    await Promise.all(
      stopLocalizationIds.map((cultureId) =>
        api.delete(`/stops/${stopId}/localizations/${cultureId}`)
      )
    );
  }
);

export const getStopCodes = createAsyncThunk<
  { codes: Array<StopCode> },
  Stop['guid']
>('stop/getCodes', async (stopId) => ({
  codes: (await api.get(`/stops/${stopId}/codes`)).data.items,
}));

export const createUpdateStopCode = createAsyncThunk<
  void,
  { codeListId: string; code: string }
>('stop/createUpdateCode', async ({ codeListId, code }, { getState }) => {
  await api.put(
    `/stops/${currentStopSelector(getState())!.guid}/codes/${codeListId}`,
    { code }
  );
});

export const deleteStopCodes = createAsyncThunk<void, Array<string>>(
  'stop/deleteCode',
  async (codeListIds, { getState }) => {
    await Promise.all(
      codeListIds.map((codeListId) =>
        api.delete(
          `/stops/${currentStopSelector(getState())!.guid}/codes/${codeListId}`
        )
      )
    );
  }
);

export const getStopPlatforms = createAsyncThunk<
  { platforms: Array<StopPlatform> },
  Stop['guid']
>('stop/getPlatforms', async (stopId) => ({
  platforms: (await api.get(`/stops/${stopId}/platforms`)).data.items,
}));

export const createUpdateStopPlatform = createAsyncThunk<
  void,
  Partial<StopPlatform>
>('stop/createUpdatePlatform', async (payload, { getState }) =>
  (async (stopId) =>
    await (payload.id ? api.put : api.post)(`/stops/${stopId}/platforms`, {
      platforms: [{
        ...payload,
        isWheelchairAccessible: Boolean(payload.isWheelchairAccessible),
      }],
    }))(currentStopSelector(getState())!.guid)
);

export const deleteStopPlatform = createAsyncThunk<
  void,
  Array<StopPlatform['id']>
>('stop/deletePlatform', async (platformIds, { getState }) => {
  await Promise.all(
    platformIds.map((platformId) =>
      api.delete(
        `/stops/${
          currentStopSelector(getState())!.guid
        }/platforms/${platformId}`
      )
    )
  );
});

export const getStopPlatformLocalizations = createAsyncThunk<
  Array<StopPlatformLocalization>,
  StopPlatform['id']
>(
  'stop/getPlatformLocalizations',
  async (platformId) =>
    (await api.get(`/platforms/${platformId}/localizations`)).data.items
);

export const createUpdateStopPlatformLocalization = createAsyncThunk<
  void,
  {
    platformId: StopPlatform['id'];
    payload: Partial<
      StopPlatformLocalization & {
        culture: StopPlatformLocalization['culture']['id'];
      }
    >;
  }
>('stop/createUpdatePlatformLocalization', async ({ platformId, payload }) => {
  (
    await (payload.id ? api.put : api.post)(
      payload.id
        ? `/platforms/${platformId}/localizations/${payload.id}`
        : `/platforms/${platformId}/localizations`,
      payload
    )
  ).data;
});

export const deleteStopPlatformLocalization = createAsyncThunk<
  void,
  {
    platformId: StopPlatform['id'];
    localizationId: StopPlatformLocalization['id'];
  }
>('stop/deletePlatformLocalization', async ({ platformId, localizationId }) => {
  await api.delete(`/platforms/${platformId}/localizations/${localizationId}`);
});

export const getStopAttributes = createAsyncThunk<
  { attributes: Array<StopPlatform> },
  Stop['guid']
>('stop/getAttributes', async (stopId) => ({
  attributes: (await api.get(`/stops/${stopId}/attributes`)).data.items,
}));

export const createStopAttributes = createAsyncThunk<void, StopAttribute['id']>(
  'stop/createAttributes',
  async (attributeId, { getState }) => {
    await api.post(
      `/stops/${currentStopSelector(getState())!.guid}/attributes`,
      {
        ids: [attributeId],
      }
    );
  }
);

export const deleteStopAttributes = createAsyncThunk<
  void,
  Array<StopAttribute['id']>
>('stop/deleteAttributes', async (attributeIds, { getState }) => {
  const stopId = currentStopSelector(getState())!.guid;
  await Promise.all(
    attributeIds.map((attributeId) =>
      api.delete(`/stops/${stopId}/attributes/${attributeId}`)
    )
  );
});

export const deleteStopAttribute = createAsyncThunk<void, StopAttribute['id']>(
  'stop/deleteAttribute',
  async (attributeId, { getState }) => {
    await api.delete(
      `/stops/${
        currentStopSelector(getState())!.guid
      }/attributes/${attributeId}`
    );
  }
);
