import { FC, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Layout } from '@fleet/shared/components/Layout';
import { AddButton, CardHeader, Loadable, SearchResult } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { attributesLoadingSelector } from 'features/loading/loadingSelectors';
import { getAttributes } from 'features/attribute/attributeActions';
import { attributesSelector } from 'features/attribute/attributeSelectors';
import { CardContent, Grid } from '@mui/material';
import { AttributeCard } from 'routes/Attributes/AttributeCard';
import { AttributeDetails } from 'routes/Attributes/AttributeDetails';

interface AttributesProps {}

export const Attributes: FC<AttributesProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(attributesLoadingSelector);
  const attributes = useSelector(attributesSelector);

  useEffect(() => {
    dispatch(getAttributes());
  }, [dispatch]);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransTitle i18nKey="stopAttributes" />}>
            <AddButton onClick={() => history.push('/attributes/create')}>
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/attributes/:action(create|edit)/:id?"
              component={AttributeDetails}
              exact
            />
          </Switch>
        }
      >
        <SearchResult
          results={attributes.length}
          i18nKey="noDataAdded"
          loading={loading}
        >
          <CardContent sx={{ p: 2, flexGrow: 1 }}>
            <Grid container spacing={3}>
              {attributes.map((attribute) => (
                <Grid key={attribute.id} item sm={4} md={3}>
                  <AttributeCard attribute={attribute} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </SearchResult>
      </Layout>
    </Loadable>
  );
};
