import { classificationReducer } from 'features/classification/classificationReducer';
import { combineReducers } from '@reduxjs/toolkit';
import { loadingReducer } from 'features/loading/loadingReducer';
import { commonReducer } from 'features/common/commonReducer';
import { attributeReducer } from 'features/attribute/attributeReducer';
import { stopReducer } from 'features/stop/stopReducer';

export default combineReducers({
  loading: loadingReducer,
  classification: classificationReducer,
  common: commonReducer,
  stops: stopReducer,
  attributes: attributeReducer,
});
