import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransAlert = createTrans({
  attributeCreated: (
    <Trans
      i18nKey="alert.attributeCreated"
      defaults="Stop attribute has been created"
    />
  ),
  attributeDeleted: (
    <Trans
      i18nKey="alert.attributeDeleted"
      defaults="Stop attribute was deleted"
    />
  ),
  attributeLocalizationDeleted: (
    <Trans
      i18nKey="alert.attributeLocalizationDeleted"
      defaults="Stop attribute localization(s) has been deleted"
    />
  ),
  attributeUpdated: (
    <Trans
      i18nKey="alert.attributeUpdated"
      defaults="Stop attribute has been updated"
    />
  ),
  fillAtLeastOne: (
    <Trans
      i18nKey="alert.fillAtLeastOne"
      defaults="At least one filter must be filled"
    />
  ),
  stopCreated: <Trans i18nKey="alert.stopCreated" defaults="New stop added" />,
  stopDeleted: (
    <Trans i18nKey="alert.stopDeleted" defaults="Stop was deleted" />
  ),
  stopUpdated: (
    <Trans i18nKey="alert.stopUpdated" defaults="Stop was updated" />
  ),
  stopCodeCreated: (
    <Trans i18nKey="alert.stopCodeCreated" defaults="Stop code is created" />
  ),
  stopCodeDeleted: (
    <Trans i18nKey="alert.stopCodeDeleted" defaults="Stop code(s) is deleted" />
  ),
  stopCodeUpdated: (
    <Trans i18nKey="alert.stopCodeUpdated" defaults="Stop code is updated" />
  ),
  stopLocalizationCreated: (
    <Trans
      i18nKey="alert.stopLocalizationCreated"
      defaults="New stop localization added"
    />
  ),
  stopLocalizationDeleted: (
    <Trans
      i18nKey="alert.stopLocalizationDeleted"
      defaults="Stop localization(s) was deleted"
    />
  ),
  stopLocalizationUpdated: (
    <Trans
      i18nKey="alert.stopLocalizationUpdated"
      defaults="Stop localization was updated"
    />
  ),
  stopPlatformCreated: (
    <Trans
      i18nKey="alert.stopPlatformCreated"
      defaults="Stop platform is created"
    />
  ),
  stopPlatformDeleted: (
    <Trans
      i18nKey="alert.stopPlatformDeleted"
      defaults="Stop platform(s) list is deleted"
    />
  ),
  stopPlatformUpdated: (
    <Trans
      i18nKey="alert.stopPlatformUpdated"
      defaults="Stop platform is updated"
    />
  ),
  stopPlatformLocalizationCreated: (
    <Trans
      i18nKey="alert.stopPlatformLocalizationCreated"
      defaults="Stop platform localization is created"
    />
  ),
  stopPlatformLocalizationDeleted: (
    <Trans
      i18nKey="alert.stopPlatformLocalizationDeleted"
      defaults="Stop platform localization list is deleted"
    />
  ),
  stopPlatformLocalizationUpdated: (
    <Trans
      i18nKey="alert.stopPlatformLocalizationUpdated"
      defaults="Stop platform localization is updated"
    />
  ),
});
