import { createReducer } from '@reduxjs/toolkit';
import { Stop, StopSearchFilter } from 'dto/stop';
import {
  clearStop,
  clearStopsList,
  createStop,
  getStop,
  getStopAttributes,
  getStopCodes,
  getStopLocalizations,
  getStopPlatforms,
  getStopsList,
  setStopsFilter,
  updateStop,
} from 'features/stop/stopActions';
import { Pagination } from '@fleet/shared/dto/pagination';

interface StopState {
  current?: Stop;
  filter: Partial<StopSearchFilter>;
  search?: Pagination<Stop>;
}

const initialState: StopState = {
  filter: {},
};

export const stopReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearStopsList, (state) => {
      state.search = undefined;
    })
    .addCase(getStopsList.rejected, (state) => {
      state.search = undefined;
    })
    .addCase(getStopsList.fulfilled, (state, action) => {
      state.search = action.payload;
    })
    .addCase(setStopsFilter, (state, action) => {
      state.filter = action.payload ?? {};
    })
    .addCase(clearStop, (state) => {
      state.current = undefined;
    })
    .addMatcher(
      (action) =>
        [
          getStop.fulfilled.type,
          createStop.fulfilled.type,
          updateStop.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        state.current = action.payload;
      }
    )
    .addMatcher(
      (action) =>
        [
          getStopLocalizations.fulfilled.type,
          getStopCodes.fulfilled.type,
          getStopPlatforms.fulfilled.type,
          getStopAttributes.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        if (state.current)
          state.current = {
            ...state.current,
            ...action.payload,
          };
      }
    );
});
