import { createLoadingSelector } from 'store/utils';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import {
  createOrUpdateAttribute,
  createOrUpdateAttributeLocalization,
  deleteAttribute,
  deleteAttributeLocalization,
  getAttribute,
  getAttributes,
} from 'features/attribute/attributeActions';
import {
  createStop,
  createStopAttributes,
  createUpdateStopCode,
  createUpdateStopLocalization,
  createUpdateStopPlatform,
  createUpdateStopPlatformLocalization,
  deleteStopAttribute,
  deleteStopAttributes,
  deleteStopCodes,
  deleteStopLocalizations,
  deleteStopPlatform,
  deleteStopPlatformLocalization,
  getStop,
  getStopAttributes,
  getStopCodes,
  getStopLocalizations,
  getStopPlatformLocalizations,
  getStopPlatforms,
  getStopsList,
  updateStop,
} from 'features/stop/stopActions';

export const appLoadingSelector = createLoadingSelector(
  getClassifications,
  getBusinessEntities
);

/**
 * Attributes
 */
export const attributesLoadingSelector = createLoadingSelector(getAttributes);

export const currentAttributeLoadingSelector = createLoadingSelector(
  getAttribute,
  createOrUpdateAttribute,
  deleteAttribute,
  createOrUpdateAttributeLocalization,
  deleteAttributeLocalization
);

/**
 * Stops
 */
export const stopListLoadingSelector = createLoadingSelector(getStopsList);

export const currentStopLoadingSelector = createLoadingSelector(
  createStop,
  getStop,
  updateStop,
  createUpdateStopLocalization,
  deleteStopLocalizations,
  getStopLocalizations,
  getStopPlatforms,
  createUpdateStopPlatform,
  deleteStopPlatform,
  getStopCodes,
  createUpdateStopCode,
  deleteStopCodes,
  getStopAttributes,
  createStopAttributes,
  deleteStopAttributes,
  deleteStopAttribute
);

export const stopPlatformLocalizationsLoadingSelector = createLoadingSelector(
  getStopPlatformLocalizations,
  createUpdateStopPlatformLocalization,
  deleteStopPlatformLocalization
);
